<template>
  <v-dialog v-model="show" width="45%" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" elevation="0" color="primary">
        <v-icon left> mdi-plus </v-icon>
        Add New Faq
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="justify-center"> Add New FAQ </v-card-title>
      <v-divider></v-divider>
      <v-progress-linear
        v-show="loading"
        indeterminate
        color="primary"
        background-opacity="0"
      >
      </v-progress-linear>

      <v-card-text>
        <v-form ref="addFaqForm" lazy-validation class="px-6">
          <v-row no-gutters>
            <v-col cols="12">
              <v-select
                v-if="
                  (isSuperAdmin || isClientAdmin) && userRole !== 'State Admin'
                "
                v-model="faq.courtLocationCode"
                :items="getClientConfig.courtInfo"
                :rules="rules.courtLocationCode"
                label="Courtname"
                required
                item-text="name"
                item-value="alias"
              ></v-select>

              <v-select
                v-model="faq.category"
                :items="categories"
                label="Category"
                required
                :disabled="!faq.courtLocationCode"
                :rules="rules.category"
              >
              </v-select>

              <v-text-field
                v-model="faq.question"
                label="Question"
                required
                :rules="rules.question"
                :disabled="!faq.category"
              >
              </v-text-field>

              <v-textarea
                v-model="faq.answer"
                label="Answer"
                required
                :disabled="!faq.category"
                :rules="rules.answer"
              ></v-textarea>

              <v-row class="align-center">
                <v-col cols="3">
                  <v-switch
                    v-model="faq.showmap"
                    label="Show Map"
                    :false-value="false"
                    :true-value="true"
                    :disabled="!faq.category || faq.courthouse"
                  ></v-switch>
                </v-col>
                <v-col cols="9" v-if="faq.showmap">
                  <v-select
                    v-model="faq.mapName"
                    :items="maps"
                    label="Map Name"
                    :disabled="!faq.showmap"
                    :rules="rules.mapName"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="align-center">
                <v-col cols="3">
                  <v-switch
                    v-model="faq.showUrl"
                    label="Show URL"
                    :false-value="false"
                    :true-value="true"
                    :disabled="!faq.category || faq.courthouse"
                  ></v-switch>
                </v-col>
                <v-col cols="9" v-if="faq.showUrl">
                  <v-text-field
                    v-model="faq.url"
                    label="Url Details"
                    :disabled="!faq.showUrl"
                    :rules="rules.url"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" align="end">
              <v-btn
                color="error"
                dark
                large
                @click="closeCreateNewFaqDialog()"
                class="mx-2"
                width="175"
                outlined
              >
                <v-icon left>mdi-close</v-icon>
                Close
              </v-btn>
              <v-btn
                color="primary"
                dark
                large
                @click="addFaqtoCMS"
                width="175"
                
              >
                <v-icon left>mdi-plus</v-icon>
                SAVE FAQ
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "addNewFaq",
  data() {
    return {
      show: null,
      allCategoriesData: [],
      loading: false,
      userRole: "",
      mapsData: [],
      faq: {
        question: "",
        answer: "",
        category: "",
        courtLocationCode: "",
        showmap: false,
        mapName: "",
        showUrl: false,
        url: "",
      },
      rules: {
        category: [(v) => !!v || "Category is required"],
        courtLocationCode: [(v) => !!v || "Court Name required"],
        answer: [(v) => !!v || "Answer is required"],
        question: [(v) => !!v || "Question is required"],
        url: [
          (v) => !!v || "URL is required",
          (v) =>
            !v ||
            /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(v) ||
            "Invalid URL format",
        ],
        mapName: [
          (v) => (this.faq.showmap ? !!v || "Map Name is required" : true), 
        ],
      },
    };
  },
  computed: {
    ...mapGetters([
      "userRoles",
      "allRegisteredKiosks",
      "assignedRole",
      "getClientConfig",
      "isSuperAdmin",
      "userProfile",
      "isClientAdmin",
      "isAdmin",
    ]),

    categories() {
      let categories = [];
      if (this.isSuperAdmin || this.isClientAdmin) {
        if (this.faq.courtLocationCode === "catonsvilleDC") {
          this.allCategoriesData
            .filter(
              (category) => category.courtLocationCode === "catonsvilledc"
            )
            .forEach((category) => categories.push(category.categoryName));
        } else if (this.faq.courtLocationCode === "howardcc") {
          this.allCategoriesData
            .filter((category) => category.courtLocationCode === "howardcc")
            .forEach((category) => categories.push(category.categoryName));
        } else if (this.faq.courtLocationCode === "charlescc") {
          console.log("comning inside charles");
          this.allCategoriesData
            .filter(
              (category) =>
                category.courtLocationCode === "charlesccak,charlesccrk"
            )
            .forEach((category) => categories.push(category.categoryName));
        }
      } else if (this.isAdmin) {
        this.userRoles.forEach((role) => {
          //setting faq.courthouse as
          this.faq.courtLocationCode = this.userProfile.data().courthouse;

          //admin of respective courts
          if (this.userProfile.data().courthouse === "charlescc") {
            this.allCategoriesData
              .filter(
                (category) =>
                  category.courtLocationCode === "charlesccak,charlesccrk"
              )
              .forEach((category) => categories.push(category.categoryName));
          } else {
            this.allCategoriesData
              .filter(
                (category) =>
                  category.courtLocationCode ===
                  this.userProfile.data().courthouse
              )
              .forEach((category) => categories.push(category.categoryName));
          }
        });
      } else {
        //
      }

      return categories.sort();
    },

    maps() {
      let maps = [];
      if (this.isSuperAdmin || this.isClientAdmin) {
        if (this.faq.courtLocationCode === "catonsvilleDC") {
          this.mapsData
            .filter((map) => map.kioskLocationCode === "catonsvilledc")
            .forEach((map) => maps.push(map.mapName));
        } else if (this.faq.courtLocationCode === "howardcc") {
          this.mapsData
            .filter((map) => map.kioskLocationCode === "howardcc")
            .forEach((map) => maps.push(map.mapName));
        } else if (this.faq.courtLocationCode === "charlescc") {
          console.log("coming inside charles");
          this.mapsData
            .filter(
              (map) => map.kioskLocationCode === "charlesccrk,charlesccak"
            )
            .forEach((map) => maps.push(map.mapName));
          this.mapsData
            .filter((map) => map.kioskLocationCode === "charlesccak")
            .forEach((map) => maps.push(map.mapName));
          this.mapsData
            .filter((map) => map.kioskLocationCode === "charlesccrk")
            .forEach((map) => maps.push(map.mapName));
        }
      } else if (this.isAdmin) {
        this.userRoles.forEach((role) => {
          //setting faq.courthouse as
          this.faq.courtLocationCode = this.userProfile.data().courthouse;

          //admin of respective courts
          if (this.userProfile.data().courthouse === "charlescc") {
            this.mapsData
              .filter(
                (map) =>
                  map.kioskLocationCode === "charlesccak,charlesccrk" ||
                  map.kioskLocationCode === "charlesccak" ||
                  map.kioskLocationCode === "charlesccrk"
              )
              .forEach((map) => maps.push(map.mapName));
          } else {
            this.mapsData
              .filter(
                (map) =>
                  map.kioskLocationCode === this.userProfile.data().courthouse
              )
              .forEach((map) => maps.push(map.mapName));
          }
        });
      } else {
        //
      }
      console.log(maps, "This is the maps");
      return maps.sort();
    },
  },
  methods: {
    async addFaqtoCMS() {
      if (this.$refs.addFaqForm.validate()) {
        this.loading = true;
        console.log("New Added Faq", this.faq);
        this.faq["addedBy"] = this.userProfile.data().email
        this.$store.dispatch("addNewFaq", this.faq).then(
          (response) => {
            console.log(response.data, "added and now logged");
            // this.$store.state.faqs.push(response.data)
            
            this.$store.commit("addFaq", response.data);
            this.loading = false;
            this.show = false;
            this.$store.dispatch("showSnackBar", {
              text: response.msg,
              color: "success",
            });
            this.$refs.addFaqForm.reset();
          },
          (error) => {
            console.log(error, "This is error");
            this.loading = false;
            this.show = false;
            this.$store.dispatch("showSnackBar", {
              text: error,
              color: "error",
            });
          }
        );
      }
    },

    closeCreateNewFaqDialog() {
      this.show = false;
      this.$refs.addFaqForm.reset();
    },
    fetchCategories() {
      this.$store.dispatch("getFaqCategories").then(
        (response) => {
          this.loading = false;
          // Extract category names and assign to allCategories
          this.allCategoriesData = response;
        },
        (error) => {
          this.loading = false;
          this.$store.dispatch("showSnackBar", {
            text: error,
            color: "error",
          });
        }
      );
    },

    fetchMaps() {
      this.loading = true;
      let courtCode =
        this.isSuperAdmin || this.isClientAdmin
          ? "NA"
          : this.userProfile.data().courthouse;

      this.$store
        .dispatch("getMaps", courtCode)
        .then((response) => {
          this.mapsData = response;
          console.log(this.mapsData, "Assigned Maps");
        })
        .catch((error) => {
          console.error("Error in fetchFaqs:", error);
          this.$store.dispatch("showSnackBar", { text: error, color: "error" });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.fetchCategories();
    this.fetchMaps();
  },
};
</script>
<style>
.alertBorder {
  border-radius: 0px !important;
}
</style>
