import axios from "axios";
export default {
  state: {
    faqCategories: [],
    faqs: [],
    linkId: null,
    maps: [],
  },
  getters: {
    faqCategories: (state) => state.faqCategories,
    faqs: (state) => state.faqs,
    linkId: (state) => state.linkId,
    maps: (state) => state.maps,
  },
  mutations: {
    setFaqCategories(state, categories) {
      console.log("Setting faqCategories:", categories);
      state.faqCategories = categories;
    },
    setFaqs(state, faqs) {
      console.log("Setting FAQs:", faqs);
      state.faqs = faqs;
    },
    setLinkId(state, value) {
      state.linkId = value;
    },
    setMaps(state, maps) {
      state.maps = maps;
    },
    addFaq(state,faq){
        state.faqs.push(faq)
    }
  },
  actions: {

    async addNewFaq({ state, commit, getters }, faqData) {
      console.log("Inside add new Faq in cms", faqData);
      if (faqData.courtLocationCode === "charlescc") {
        faqData.courtLocationCode = "charlesccak,charlesccrk";
      }
      faqData.linkId = state.linkId;
      if(faqData.showUrl){
        faqData.urlDetails = {
          urllist: [
            {
              name: "Name",
              link: faqData.url,
              desc: "Desc",
            },
          ],
        };
      }
      
      delete faqData.url;

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://cms.mdcourts.arsconnect.com/faqs",
        headers: {
          "Content-Type": "application/json",
        },
        data: faqData,
      };
      
      return axios
        .request(config)
        .then((response) => {
          console.log(response, "this is what is wrong");
          if (response.status === 200) {
            console.log(JSON.stringify(response.data));
            commit("setLinkId", faqData.linkId + 1);
            return {data : response.data , msg:"FAQ ADDED SUCCESSFULLY."};
          } else {
            console.log("Inside error");
            throw new Error("Failed to ADD FAQs");
            // return {msg: "Failed to ADD FAQs"};
          }
        })
        .catch((error) => {
          console.log(error);
          throw new Error("Failed to ADD FAQs");
        });
      //   console.log("Response: ", );
    },

    async getFaqs({ state, commit }, courtLocationCode){
      if (state.faqs.length > 0) {
        console.log("FAQs already fetched.");
        return state.faqs; // Return the existing data
      }

      console.log("Fetching FAQs for:", courtLocationCode);
      const url =
        courtLocationCode === "NA"
          ? `https://cms.mdcourts.arsconnect.com/faqs?_limit=-1`
          : `https://cms.mdcourts.arsconnect.com/faqs?_where[courtLocationCode]=${courtLocationCode}&_limit=-1`;

      try {
        const response = await axios.get(url, {
          headers: { "Content-Type": "application/json" },
        });

        if (response.status === 200) {
          console.log("FAQs fetched successfully:", response.data);
          commit("setFaqs", response.data); // Commit to mutation

          const linkIDs = response.data.map((item) => item.linkId);
          const maxlinkID = Math.max(...linkIDs);
          console.log(maxlinkID, "This is where linkId is starting");
          commit("setLinkId", maxlinkID + 1);

          return response.data;
        } else {
          throw new Error("Failed to fetch FAQs");
        }
      } catch (error) {
        console.error("Error fetching FAQs:", error.message);
        throw new Error(error.message);
      }
    },
    async getFaqCategories({ state, commit }) {
      // Check if FAQ categories are already loaded
      if (state.faqCategories && state.faqCategories.length > 0) {
        console.log("FAQ categories already fetched.");
        return state.faqCategories; // Return existing data
      }

      try {
        const response = await axios.get(
          "https://cms.mdcourts.arsconnect.com/faq-categories",
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        console.log("Response from Strapi for FAQ categories: ", response);
        // Check the response status
        if (response.status !== 200) {
          throw new Error("Failed to fetch FAQ categories");
        }

        commit("setFaqCategories", response.data); // Commit the data to the store
        return response.data;
      } catch (error) {
        console.error("Error fetching FAQ categories:", error);
        throw error;
      }
    },

    async getMaps({ state, commit }, courtLocationCode) {
      if (state.maps.length > 0) {
        console.log("Maps already fetched");
        return state.maps; // Return the existing data
      }

      console.log("Fetching Maps for:", courtLocationCode);
      let url;
      if (courtLocationCode !== "charlescc") {
        url =
          courtLocationCode === "NA"
            ? `https://cms.mdcourts.arsconnect.com/maps?_limit=-1`
            : `https://cms.mdcourts.arsconnect.com/maps?_where[kioskLocationCode]=${courtLocationCode}&_limit=-1`;
      } else {
        url = `https://cms.mdcourts.arsconnect.com/maps?_where[kioskLocationCode_contains]=charles&_limit=-1`;
      }

      try {
        const response = await axios.get(url, {
          headers: { "Content-Type": "application/json" },
        });

        if (response.status === 200) {
          console.log("Maps fetched successfully:", response.data);
          commit("setMaps", response.data); // Commit to mutation
          return response.data;
        } else {
          throw new Error("Failed to fetch FAQs");
        }
      } catch (error) {
        console.error("Error fetching FAQs:", error.message);
        throw new Error(error.message);
      }
    },
  },
};
