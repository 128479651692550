<template>
  <v-container fluid>
    <v-card elevation="0" class="px-3">
      <v-card-title class="text-h4">
        Frequently Asked Questions
        <v-spacer></v-spacer>
        <addNewFaq></addNewFaq>
      </v-card-title>
    </v-card>

    <v-card elevation="0" class="px-3 mt-3">
      <v-card-title>
        <template>
          <v-row>
            <v-col cols="8">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search Faqs"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="3" v-if="isSuperAdmin || isClientAdmin">
              <v-select
                v-model="selectedCounty"
                :items="countyOptions"
                label="Filter by County"
                prepend-icon="mdi-filter"
                @change="handleCountyChange"
              >
              </v-select>
            </v-col>
            <v-col
              cols="1"
              v-if="isSuperAdmin || (isClientAdmin && selectedCounty)"
              align-self="center"
            >
              <v-icon class="cursor-pointer" @click="resetSelectedCounty">
                mdi-filter-remove
              </v-icon>
            </v-col>
          </v-row>
        </template>
      </v-card-title>
      <v-data-table
        :loading="loading"
        :headers="getHeaders"
        :items="faqs"
        :search="search"
        item-key="id"
        single-expand
        sort-by="updated_at"
        sort-desc
      >
        <template v-slot:item.question="{ item }">
          <span>
            {{ truncateText(item.question, 50) }}
          </span>
        </template>
        <template v-slot:item.answer="{ item }">
          <span>
            {{ truncateText(item.answer, 50) }}
          </span>
        </template>
        <template v-slot:item.category="{ item }">
          {{ item.category }}
        </template>
        <template v-slot:item.updated_at="{ item }">
          {{ item.updated_at | formatDate }}
        </template>

        <template v-slot:item.courtLocationCode="{ item }">
          <v-chip
            v-if="isSuperAdmin || isClientAdmin"
            color="primary"
            text-color="black"
            outlined
          >
            {{ item.courtLocationCode }}
          </v-chip>
          <span v-else>
            {{ item.courtLocationCode }}
          </span>
        </template>

        <!-- Fix the slot name for 'data-table-expand' -->
        <template v-slot:item.actions="{ item }">
          <viewFaq :faqIndex="item"> </viewFaq>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import addNewFaq from "@/components/dialogBox/cms/addNewFaq.vue";
import viewFaq from "@/components/dialogBox/cms/viewFaq.vue";

export default {
  name: "cms",
  components: {
    addNewFaq,
    viewFaq,
  },
  data() {
    return {
      loading: true,
      expanded: [],
      allFaqs: [],
      search: "",
      dialog: false,
      selectedItem: {},
      //   filterOptions: [
      //   { text: 'Select Filter', value: '' },
      //   { text: 'Status', value: 'status' },
      //   { text: 'Severity', value: 'severity' },
      //   { text: 'County', value: 'county' } // Add this option
      // ],
      countyOptions: [],
      selectedCounty: null,

      headers: [
        { text: "Question", align: "start", value: "question" },
        { text: "Answer", value: "answer" },
        { text: "Category", value: "category" },
        { text: "Last Updated", value: "updated_at" },
        { text: "More Information", value: "actions" },
      ],
      superAdminheaders: [
        { text: "Question", align: "start", value: "question" },
        { text: "Answer", value: "answer" },
        { text: "Category", value: "category" },
        { text: "Last Updated", value: "updated_at" },
        { text: "Court", value: "courtLocationCode" },
        { text: "More Information", value: "actions" },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "userRoles",
      "assignedRole",
      "isSuperAdmin",
      "isClientAdmin",
      "isAdmin",
      "userProfile",
      "faqs",
      "getClientConfig",
    ]),
    getHeaders() {
      if (this.isSuperAdmin || this.isClientAdmin) {
        return this.superAdminheaders;
      } else {
        return this.headers;
      }
    },
    faqs() {
      if (!this.allFaqs) return [];

      // console.log(this.getClientConfig.courtInfo,"Names");
      //   const countyMapping = {
      //     "charlesccak,charlesccrk": "Charles County",
      //     howardcc: "Howard County",
      //     catonsvilledc: "Catonsville County",
      //   };
      let countyMapping = {};
      if (this.isClientAdmin || this.isSuperAdmin) {
        this.getClientConfig.courtInfo.forEach((ele) => {
          if (ele.alias === "charlescc") {
            countyMapping["charlesccak,charlesccrk"] = ele.name;
          } else {
            countyMapping[ele.alias] = ele.name;
          }
          this.countyOptions.push(ele.name);
        });
        console.log(countyMapping, "this is countyMapping");
      }

      return this.allFaqs
        .filter((faq) =>
          this.selectedCounty
            ? countyMapping[faq.courtLocationCode] === this.selectedCounty ||
              (faq.courtLocationCode === "catonsvilledc" &&
                "Catonsville District Court" === this.selectedCounty)
            : true
        )
        .map((faq) => ({
          ...faq,
          courtLocationCode:
            faq.courtLocationCode === "catonsvilledc"
              ? "Catonsville District Court"
              : countyMapping[faq.courtLocationCode] || faq.courtLocationCode,
        }));
    },
  },
  filters: {
    formatDate(val) {
      if (!val) return "-";
      return moment(val).format("MMM Do YY, h:mm A");
    },

    trimLength(val) {
      if (val.length < 200) {
        return val;
      }
      return `${val.substring(0, 50)}...`;
    },
  },
  methods: {
    fetchFaqs() {
      this.loading = true;
      let courtCode =
        this.isSuperAdmin || this.isClientAdmin
          ? "NA"
          : this.userProfile.data().courthouse;
      if (courtCode === "charlescc") {
        courtCode = "charlesccak,charlesccrk";
      }
      this.$store
        .dispatch("getFaqs", courtCode)
        .then((response) => {
          this.allFaqs = response;
        })
        .catch((error) => {
          console.error("Error in fetchFaqs:", error);
          this.$store.dispatch("showSnackBar", { text: error, color: "error" });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    truncateText(text, limit) {
      return text.length > limit ? text.substring(0, limit) + "..." : text;
    },
    handleCountyChange() {
      // Your function logic here
      console.log("Selected County:", this.selectedCounty);
      // Perform any necessary actions, such as filtering data or making API calls
    },

    resetSelectedCounty() {
      this.selectedCounty = null;
    },

    showDetails(item) {
      console.log(item, "I got this");
      this.selectedItem = item; // Set the selected item
      this.dialog = true; // Open the dialog
    },
  },
  mounted() {
    console.log("Mounted Here");
    this.fetchFaqs();
  },
};
</script>
