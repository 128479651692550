<template>
  <v-dialog v-model="show" max-width="40%">
    <template v-slot:activator="{ on, attrs }">
    <!-- <div class="d-flex justify-center"> -->
      <v-icon
        v-bind="attrs"
        v-on="on"
        small
        class="mr-2"
        @click="viewUser"
      >
        mdi-eye
      </v-icon>
      <!-- </div> -->
    </template>
    <v-card>
      <v-card-title class="justify-center">
        {{ formTitle }}
      </v-card-title>
      <v-divider></v-divider>
      <v-progress-linear
        v-show="loading"
        indeterminate
        color="primary"
        background-opacity="0"
      >
      </v-progress-linear>
      <v-card-text v-if="actionType === 'view'">
        <v-form ref="editViewForm" lazy-validation class="px-6">
          <v-text-field
            v-model="faq.courtLocationCode"
            label="CourtName"
            required
            readonly
          >
          </v-text-field>
          <v-text-field v-model="faq.question" label="Question" required readonly>
          </v-text-field>
          <v-textarea
            v-model="faq.answer"
            label="Answer"
            required
            readonly
          ></v-textarea>

          <v-row>
            <v-col cols="3">
              <v-switch
                v-model="faq.showmap"
                :label="'Show Map'"
                :false-value="false"
                :true-value="true"
                readonly
              ></v-switch>
            </v-col>
            <v-col cols="9" v-if="faq.showmap">
              <v-text-field
                v-model="faq.mapName"
                label="Map Name"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- URL Display Logic -->
          <v-row>
            <v-col cols="3">
              <v-switch
                v-model="faq.showUrl"
                :label="'Show URL'"
                :false-value="false"
                :true-value="true"
                readonly
              ></v-switch>
            </v-col>
            <v-col cols="9" v-if="faq.showUrl">
              <v-text-field
                v-model="faq.urlDetails.urllist[0].link"
                label="Url Details"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" outlined @click="show = !show" width="175">
              <v-icon left>mdi-close</v-icon>
              Close
            </v-btn>
            <!-- <v-btn color="primary" @click="updateUser" width="175">
              <v-icon left>mdi-content-save-cog</v-icon>
              Save
            </v-btn> -->
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "viewFaq",
  props: {
    faqIndex: Object,
  },
  data() {
    return {
      show: null,
      actionType: "",
      formTitle: "",
      loading: false,
      //   faq: {
      //     name: '',
      //     email: '',
      //     userRole: '',
      //     courthouse: '',
      //     id: null,
      //   },
      //   rules: {
      //     nameRules: [
      //       v => !!v || 'Full name is required',
      //     ],
      //     emailRules: [
      //       v => !!v || 'E-mail is required',
      //       v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      //     ],
      //   }
    };
  },
  methods: {
    viewUser() {
      this.formTitle = "View Faq";
      this.actionType = "view";
      //this.editUserIndex = this.allRegisteredUsers.indexOf(this.user);
      this.faq = Object.assign({}, this.faqIndex);
      this.faq.id = this.faqIndex.id;
    //   console.log(this.faq, "this is val");
    },
  },
  computed: {
    ...mapGetters([
      "userRoles",
      "allRegisteredUsers",
      "allRegisteredKiosks",
      "isSuperAdmin",
      "assignedRole",
      "getClientConfig",
      "isAdmin",
      "isClientAdmin",
    ]),
    roles() {
      let roleName = [];
      if (this.isSuperAdmin) {
        this.userRoles.forEach((role) => roleName.push(role.data.name));
      } else if (this.isAdmin) {
        this.userRoles.forEach((role) => {
          if (
            role.data.name !== "Super Admin" &&
            role.data.name !== "State Admin"
          ) {
            roleName.push(role.data.name);
          }
        });
      } else if (this.isClientAdmin) {
        this.userRoles.forEach((role) => {
          if (role.data.name !== "Super Admin") {
            roleName.push(role.data.name);
          }
        });
      } else {
        //
      }

      return roleName;
    },
  },
};
</script>

